// config.js
const CONFIG = {
    // DataServerBaseUrl: 'http://localhost:8000',  // 在这里定义服务器地址
    // DataServerBaseUrl: 'https://106.54.219.117',  // 在这里定义服务器地址
    DataServerBaseUrl: 'https://www.heroofcode.com',
    // DataServerBaseUrl: 'http://www.heroofcode.com',
    
    // 可以根据需要添加其他配置

    disabledev: true,
    usecdn :true
};

export default CONFIG;
// 引入配置文件
import CONFIG from './config.js';

document.addEventListener('DOMContentLoaded', async function () {
    // 定义最大尝试次数和限制时间（单位：毫秒）
    const MAX_ATTEMPTS = 5;
    const LOCKOUT_TIME = 60 * 1000; // 1分钟

    // 检查用户是否锁定
    function isLockedOut() {
        const attempts = JSON.parse(localStorage.getItem('login_attempts')) || { count: 0, lastAttempt: 0 };
        const currentTime = Date.now();

        // 检查是否在锁定时间内
        return attempts.count >= MAX_ATTEMPTS && currentTime - attempts.lastAttempt < LOCKOUT_TIME;
    }

    // 更新登录尝试记录
    function updateLoginAttempts(success = false) {
        let attempts = JSON.parse(localStorage.getItem('login_attempts')) || { count: 0, lastAttempt: 0 };

        if (success) {
            // 如果成功登录，清除记录
            localStorage.removeItem('login_attempts');
        } else {
            // 如果失败，增加尝试次数并更新时间
            attempts.count += 1;
            attempts.lastAttempt = Date.now();
            localStorage.setItem('login_attempts', JSON.stringify(attempts));
        }
    }

    // 显示锁定倒计时
    function startLockoutCountdown(lockoutEndTime) {
        const interval = setInterval(() => {
            const remainingTime = Math.max(0, Math.floor((lockoutEndTime - Date.now()) / 1000));
            if (remainingTime === 0) {
                clearInterval(interval);
                localStorage.removeItem('login_attempts');
                document.getElementById('login-form').style.pointerEvents = 'auto'; // 恢复表单
                document.querySelector('.u-form-send-error').style.display = 'none';
                // location.reload(); // 自动刷新页面以重置表单状态
            } else {
                document.querySelector('.u-form-send-error').textContent = `尝试次数过多，请稍后再试。剩余时间：${remainingTime} 秒`;
            }
        }, 1000);
    }

    // 初始化错误消息点击事件
    const errorElement = document.querySelector('.u-form-send-error');
    if (errorElement) {
        errorElement.addEventListener('click', () => {
            errorElement.style.display = 'none';
        });
    }

    // 检查是否锁定
    if (isLockedOut()) {
        const attempts = JSON.parse(localStorage.getItem('login_attempts'));
        const lockoutEndTime = attempts.lastAttempt + LOCKOUT_TIME;

        document.querySelector('.u-form-send-error').style.display = 'block';
        document.getElementById('login-form').style.pointerEvents = 'none'; // 禁用表单

        // 显示倒计时
        startLockoutCountdown(lockoutEndTime);
        return;
    }

    // 绑定表单提交事件
    document.getElementById('login-form').addEventListener('submit', async function (event) {
        event.preventDefault(); // 阻止默认表单提交行为

        const username = document.querySelector('input[name="username"]').value;
        const password = document.querySelector('input[name="password"]').value;
        const submitButton = document.querySelector('button.u-btn-submit');

        // 显示加载状态
        submitButton.classList.add('loading');
        submitButton.disabled = true;

        try {
            // 定义获取 IP 地址的 Promise 和超时的 Promise
            const fetchIpAddress = fetch('https://api.ipify.org?format=json')
                .then(response => response.json())
                .then(data => data.ip);

            const timeout = new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(new Error('获取 IP 地址超时'));
                }, 2000); // 设置 1 秒超时
            });

            let ipAddress = null;

            // 使用 Promise.race 来实现超时机制
            await Promise.race([fetchIpAddress, timeout])
                .then(ip => {
                    ipAddress = ip;
                })
                .catch(error => {
                    console.warn(error.message);
                    // 超时或获取 IP 失败，ipAddress 保持为 null
                });

            // 准备请求体
            const requestBody = { username, password };
            if (ipAddress) {
                requestBody.ip_address = ipAddress;
            }else{
                requestBody.ip_address = "Unknow"
            }

            // 提交登录请求
            const response = await fetch(`${CONFIG.DataServerBaseUrl}/api/login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const result = await response.json();

            if (result.success) {
                // 成功登录，清除尝试记录
                updateLoginAttempts(true);

                // 显示成功消息
                document.querySelector('.u-form-send-success').style.display = 'block';
                document.querySelector('.u-form-send-error').style.display = 'none';

                // 保存 Tokens 和 Player ID 到 localStorage
                debugger
                localStorage.setItem('access_token', result.access);
                localStorage.setItem('refresh_token', result.refresh);
                localStorage.setItem('player_id', result.player_id);

                // 调用上线接口
                await fetch(`${CONFIG.DataServerBaseUrl}/api/player-online/${result.player_id}/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${result.access}`,
                        'Content-Type': 'application/json',
                    },
                });

                // 跳转到主页或编辑器页面
                window.location.href = `${window.location.origin}/matchsum.html`;
            } else {
                
                if (result.id == 3){
                    // 弹出无权限modal框
                    const noPermissionModal = document.getElementById('no-permission-modal');
                    noPermissionModal.classList.add('show');
                }
                // 登录失败，更新尝试记录
                updateLoginAttempts(false);

                const attempts = JSON.parse(localStorage.getItem('login_attempts'));
                const remainingAttempts = MAX_ATTEMPTS - attempts.count;

                if (remainingAttempts > 0) {
                    document.querySelector('.u-form-send-error').textContent = `登录失败，您还有 ${remainingAttempts} 次尝试机会`;
                } else {
                    document.querySelector('.u-form-send-error').textContent = '尝试次数过多，请稍后再试';
                    document.getElementById('login-form').style.pointerEvents = 'none'; // 禁用表单

                    // 显示倒计时
                    const lockoutEndTime = attempts.lastAttempt + LOCKOUT_TIME;
                    startLockoutCountdown(lockoutEndTime);
                }

                document.querySelector('.u-form-send-error').style.display = 'block';
                document.querySelector('.u-form-send-success').style.display = 'none';
            }
        } catch (error) {
            console.error('Login Error:', error);
            document.querySelector('.u-form-send-success').style.display = 'none';
            document.querySelector('.u-form-send-error').style.display = 'block';
            document.querySelector('.u-form-send-error').textContent = '服务器错误，请稍后再试';
        } finally {
            // 恢复按钮状态
            submitButton.classList.remove('loading');
            submitButton.disabled = false;
        }
    });
});

// 等待DOM完全加载
document.addEventListener('DOMContentLoaded', function () {
    // 获取模态窗口元素
    const modal = document.getElementById('forgot-password-modal');
    // 获取打开模态窗口的链接
    const forgotPasswordLink = document.getElementById('forgot-password-link');
    // 获取关闭按钮
    const closeButton = document.querySelector('.close-button');
    // 获取无权限登陆模态窗口元素
    const noPermissionModal = document.getElementById('no-permission-modal');
    // 获取关闭按钮
    const noPermissionCloseButton = noPermissionModal.querySelector('.close-button');

    // 当用户点击“忘记密码”链接时，打开模态窗口
    forgotPasswordLink.addEventListener('click', function () {
        modal.classList.add('show');
    });

    // 当用户点击关闭按钮时，关闭模态窗口
    closeButton.addEventListener('click', function () {
        modal.classList.remove('show');
    });

    // 当用户点击模态窗口外部区域时，关闭模态窗口
    window.addEventListener('click', function (event) {
        if (event.target === modal) {
            modal.classList.remove('show');
        }
    });

    // 当用户点击关闭按钮时，关闭模态窗口
    noPermissionCloseButton.addEventListener('click', function () {
        noPermissionModal.classList.remove('show');
    });

    // 当用户点击模态窗口外部区域时，关闭模态窗口
    window.addEventListener('click', function (event) {
        if (event.target === noPermissionModal) {
            noPermissionModal.classList.remove('show');
        }
    });
});